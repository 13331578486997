@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

pixelated-image-card {
  background-color: white;
  border-radius: 0.5em;
  width: 100%;
  max-width: 100%;
  position: relative;
  aspect-ratio: 1 / 1;
}

.before__100 {
  padding-top: 100%;
}

.pixelated-image-card__default, 
.pixelated-image-card__img,
.pixelated-image-card__active, 
.pixelated-image-card__pixels {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pixelated-image-card__img {
  object-fit: contain;
  object-position: center;
}

.pixelated-image-card__active {
  display: none;
}

.pixelated-image-card__pixel {
  background-color: white;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  will-change: opacity, visibility;
}

.pixelated-image-card__pixels {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  gap: 0;
  overflow: hidden;
  background: transparent;
}

